.App {
  text-align: center;
  background-color: #e6e6e6;
  min-height: 125vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: min(calc(9px + 2vmin));
  color: rgb(27, 27, 27);
  /* min-width: 800px; */
}

.App-header {
  max-width: 80%;
}

div.content {
  width: 52%;
  min-width: 320px;
  max-width: 700px;
  text-align: left;
  font-size: 18px;
  line-height: 1.25;
}

div.bulletpoints {
  font-size: 22px;
}

div.faq {
  padding-bottom: 100px;
}

div.faq ul>li {
  padding-top: 10px;
  padding-bottom: 5px;
}

.App-header p {
  max-width: 90%;
}

.App-link {
  color: #61dafb;
}